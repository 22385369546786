import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";

import Home from "./views/Home.vue";
import SubRouter from "./views/SubRouter.vue";
import Auth from "./views/Auth.vue";
import { AuthService } from "./services/auth";
import comumService from "./services/comum";

Vue.use(Router);

const BASE_TITLE = "TCS MMI Trends";
const TITLE_SEPARATOR = " | ";
const authService = new AuthService();

const router = new Router({
	mode: "history",

	scrollBehavior (/* to, from, savedPosition */) {
		return { x: 0, y: 0 };
	},

	routes: [
		{
			path: "/auth",
			name: "auth",
			component: Auth
		},
		{
			path: "/",
			name: "home",
			meta: {
				title: "Relatório Barkfinder",
				perms: []
			},
			component: () => import("./views/Barkfinder.vue")
		},
		{
			path: "/visualizador",
			name: "visualizador",
			meta: {
				title: "Ultimas Viagens",
				perms: []
			},
			component: () => import("./views/Visualizador.vue")
		},
		// ERROS
		{
			name: "403",
			path: "/forbidden",
			meta: {
				title: "403 - Permissão insuficiente",
				perms: []
			},
			component: () => import("./views/403.vue")
		},

		// 404 (precisa ser a última rota deste arquivo)
		{
			name: "404",
			path: "*",
			meta: {
				title: "404 - Página não encontrada",
				perms: []
			},
			component: () => import("./views/404.vue")
		}
	]
});

router.beforeEach(async (to, from, next) => {
	// Altera título da janela de acordo com a página acessada
	document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : "") + BASE_TITLE;

	// Controle de permissões de acesso
	if (!to.meta.perms) {
		// Página pública
		console.log("pag publica");
		next();
	} else if (!(await authService.isLoggedIn())) {
		// Não logado
		console.log("não logado");
		console.log(await comumService.getComumUrl());
		console.log("passou awaOt");
		//window.location.href = `${await comumService.getComumUrl()}/#!/login?redirect=${window.location.origin}&type=vue`;
		console.log(window.location.href);
		console.log("travou");
	} else {
		// Acesso permitido
		next();
	}
});

// Tooltips
router.afterEach(() => {
	const $ = require("jquery");
	$(".tooltip[role=tooltip]").tooltip("hide");
	setTimeout(() => {
		const $tooltips = $('[data-toggle="tooltip"]');
		if (window.innerWidth <= 790)
			$tooltips.tooltip("disable");
		else
			$tooltips.tooltip("enable");
	});
});

// Loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
