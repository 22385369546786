<template>
	<template-base>
		<div class="d-flex justify-content-between">
			<h1 class="align-self-center">Barkfinder</h1>
		</div>
		<p>Use o menu lateral para navegar pelo sistema.</p>
	</template-base>
</template>

<script>
	import TemplateBase from "../templates/Base";

	export default {
		components: { TemplateBase }
	};
</script>
