import Axios from "axios";

import comumService from "./comum";

export class AuthService {
	axios;

	constructor () {
		this.axios = Axios.create();
	}

	_getToken () {
		const storage = this.getModuleStorage();
		console.log(storage ? storage.token : null);
		return storage ? storage.token : null;
	}

	getModuleStorage () {
		const storage = localStorage.getItem("params");
		return storage ? JSON.parse(storage) : null;
	}

	async isLoggedIn () {
		/*if (!this._getToken()){
			console.log("deslogado")
			return false;
		}*/

		return true;//await this.isSessionValid();
	}

	async logout () {
		const comumUrl = await comumService.getComumUrl();

		try {
			await this.axios.get(`${comumUrl}/api/v1/logout`, {
				headers: {
					Authorization: "Bearer " + this._getToken()
				}
			});
		} catch (error) {
			console.log(error);
		}

		localStorage.clear();
		window.location.href = comumUrl;
	}

	async isSessionValid () {
		console.log("entrou aqui");

		try {
			const comumUrl = await comumService.getComumUrl();
			const response = await this.axios.get(comumUrl + "/api/v1/areas", {
				headers: {
					Authorization: "Bearer " + this._getToken()
				}
			});


			return response.status == 200;
		} catch (error) {
			console.log(error);
		}
	}
}
