<template>
    <div class="bg">
        <div class="changelog-container card tcs-card elevate-2">
            <div class="d-flex justify-content-between align-items-center py-1 px-2 changelog-title">
                <strong>Changelog</strong>
                <div>
                    <button :class="{'active': changelog === 1}" @click="changelog = 1" class="btn btn-sm btn-light mr-2">WEB</button>
                    <button :class="{'active': changelog === 2}" @click="changelog = 2" class="btn btn-sm btn-light">API</button>
                </div>
                <button class="btn p-0" @click="$emit('close')" title="Fechar"><x-circle-icon /></button>
            </div>
            <iframe class="iframe" :src="changelog === 2 ? changelogRoute : '/changelog.html'" sandbox="allow-popups allow-popups-to-escape-sandbox">
                Seu navegador não suporta iframes.<br />
                <a href="/changelog.html">Clique aqui</a> para ver o changelog do cliente WEB, ou
                <a :href="changelogRoute">clique aqui</a> para ver o changelog da API.
            </iframe>
        </div>
    </div>
</template>

<script>
	import api from "@/api";

	export default {
		data () {
			return {
				changelog: 1,
				changelogRoute: api.changelog
			};
		}
	};
</script>

<style scoped>
	.bg {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left:0;
		background: rgba(0, 0, 0, 0.7);
	}

	.changelog-container {
		position: absolute;
		width: 90%;
		height: 90%;
		top: 5%;
		left: 5%;
		background: white;
		border-bottom-right-radius: 0
	}

	.changelog-container .changelog-title {
		border-bottom: 1px solid black;
	}

	.changelog-container .changelog-title {
		border-bottom: 1px solid black;
	}

	.changelog-container .iframe {
		overflow: auto;
		width: 100%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
	}
</style>
