<template>
    <aside class="d-flex side-nav" ref="aside">
        <nav class="py-3 px-2 d-flex flex-column align-items-center navbar-aside" ref="navbar-aside">
            <ul class="nav nav-body w-100 flex-column">
                <NavLink v-for="link in links" :key="link.name" v-bind="link" @link="navClick" />
            </ul>
        </nav>

        <!-- <div class="splitter d-flex flex-column justify-content-center align-items-center" :class="{'is-expanded': !isBarMinimized}" @mousedown.prevent="startResize">
            <div class="handle w-100 h-100"></div>
            <svg class="arrow" id="toggleSizeAsideNav" @click.stop="toggleNavBar" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M9.984 6l6 6-6 6-1.406-1.406 4.594-4.594-4.594-4.594z"></path>
            </svg>
        </div> -->
    </aside>
</template>

<style scoped>
	.feather-dashboard {
		fill: currentColor !important;
	}

	.navbar-aside {
		width: 70px;
		overflow-y: auto;
	}

	.side-nav {
		transform: translateX(-100%) translateX(-30px);
	}

	.navbar-aside.animw {
		transition: width 0.3s ease-in-out;
	}
	.navbar-aside .nav {
		display: block;
	}
	.nav-link {
		text-overflow: ellipsis;
	}

	.splitter svg path {
		transition: transform 0.3s ease-in-out;
	}
	.splitter.is-expanded svg path {
		transform: scaleX(-1) translateX(-100%);
	}

	nav::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	nav::-webkit-scrollbar-track {
		background: #6dcff6;
	}

	nav::-webkit-scrollbar-thumb {
		background-color: #fff;
		border-radius: 20px;
	}
</style>

<script>
	import NavLink from "@/components/NavBar/NavLink";
	export default {
		components: { NavLink },

		data () {
			return {
				isBarMinimized: true, // Árvores
				originalWidth: 70,
				originalX: 0,
				originalMouseX: 0,
				minWidth: 0,
				refNav: "navbar-aside"
			};
		},

		props: {
			maxWidth: Number,
			hidden: Boolean,
			links: {
				type: Array,
				default: () => []
			}
		},

		watch: {
			hidden: "setHiddenState",
			maxWidth: "setMaxWidth"
		},

		methods: {
			startResize (e) {
				const element = this.$refs[this.refNav];

				const resize = e => {
					const width = this.originalWidth + (e.pageX - this.originalMouseX);
					element.style.width = width + "px";
					this.isBarMinimized = width <= this.minWidth;
				};

				const stopResize = () => {
					window.removeEventListener("mousemove", resize);
					window.removeEventListener("mouseup", stopResize);
					this.$emit("resize", parseInt(window.getComputedStyle(element).width) + 2);
				};

				this.originalWidth = parseFloat(getComputedStyle(element, null).getPropertyValue("width").replace("px", ""));
				this.originalX = element.getBoundingClientRect().left;
				this.originalMouseX = e.pageX;
				window.addEventListener("mousemove", resize);
				window.addEventListener("mouseup", stopResize);
			},

			toggleNavBar () {
				const element = this.$refs[this.refNav];

				this.originalWidth = parseFloat(getComputedStyle(element, null).getPropertyValue("width").replace("px", ""));
				element.classList.add("animw");

				if (this.originalWidth > this.minWidth) {
					// Minimize
					element.style.width = this.minWidth + "px";
					this.isBarMinimized = true;
				} else {
					// Maximize
					element.style.width = this.maxWidth + "px";
					this.isBarMinimized = false;
				}

				this.$emit("resize", parseInt(element.style.width) + 2);
				setTimeout(() => element.classList.remove("animw"), 300);
			},

			maximize () {
				const element = this.$refs[this.refNav];
				element.style.width = this.maxWidth + "px";
				this.isBarMinimized = false;
			},

			setHiddenState () {
				const aside = this.$refs.aside;
				if (this.hidden) {
					// Oculta barra deslizando-a para a esquerda
					aside.style.transform = "translateX(-100%) translateX(-30px)";
				} else {
					// Mostra barra deslizando-a para a direita
					aside.style.transform = "translateX(0)";
				}
			},

			setMaxWidth () {
				const element = this.$refs[this.refNav];
				element.style.maxWidth = this.maxWidth + "px";
			},

			navClick () {
				this.$emit("link");
			}
		},

		mounted () {
			const element = this.$refs[this.refNav];
			this.minWidth = parseFloat(getComputedStyle(element, null).getPropertyValue("min-width").replace("px", ""));
			this.setHiddenState();
			this.setMaxWidth();
		}
	};
</script>
